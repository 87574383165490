//Font size mixin to
@mixin font-size($font-size, $line-height: auto) {
    font-size: $font-size; 
    
    /* TODO RC - convert all outputted font sizes to rems/ems */
    @if $line-height==auto {
        line-height: $base-line-height;
    } 
    @else {
        @if (type-of($line-height)==number or $line-height==inherit or $line-height==normal) {
            line-height: $line-height;
        }
        @elseif ($line-height !=none and $line-height !=false) {
            @warn "Error! ‘#{$line-height}’ is not a valid value for `line-height`."
        }
    }
}


/* dont use placeholder as a label replacement */

@mixin placeholder() {
    ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        @content
    }
    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        @content
    }
    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        @content
    }
    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        @content
    }
    :placeholder-shown {
        /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
        @content;
    }
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin cf {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin vertical-align($alignment:middle) {
    &:before,
    &:after {
        vertical-align: $alignment;
        content: "";
        display: inline-block;
        width: 0;
        height: 100%;
    }
    /* Child element to be inline-block & vertical-aligned as above */
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

@mixin absolute-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin aspect-ratio($percentage:56.25%) {
    position: relative;
    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: $percentage;
        width: 100%;
    }
    /* Position child absolutely */
}

@mixin ui-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
    }
}

@mixin animate-prop($args...) {
    transition-property: $args;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}


/* for accessibilty purposes */
@mixin visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

@mixin unstyled-link {
    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
    &:active,
    &:focus {
        outline: none;
    }
}

@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


@mixin white-box {
    margin-bottom: sp(24);
    position: relative;
    background-color: #ffffff;
    border: 1px solid $color-grey-xdark;
    padding: 10px;
}

// @mixin animate {
//   transition: all 0.3s ease-in-out;
// }


@mixin responsiveClasses($className, $prop, $propVal) {
  $index: 1;
  @each $bp in $breakpoints {
    $key: nth($bp, 1);
    $value: nth($bp, 2);
    
    $classWithBreakpoint: $className + '-' + $key;
    $class: $classWithBreakpoint;
    
    @if $index != 1 {
      @for $i from 1 through $index - 1{ 
        $class: $class + '.' + $classWithBreakpoint;        
      }
    }
    
    @media (min-width: $value) {
        .#{$class} {
          #{$prop} : $propVal !important;
      }
    }
    $index: $index + 1;
  }
}

