.l-base-layout__content {
    .notifications-label {
        color: #5A5A5A;
        font-size: 20px;
        font-weight: 600;
        line-height: 25.14px;
        margin-bottom: 10px;
        display: block;

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 20.8px;
            color: #5A5A5A;
        }
    }

    .category-filter-section,
    .city-filter-section,
    .title-input-section,
    .message-input-section,
    .deeplink-filter-section {
        display: inline-block;
        width: 100%;

        .select-dropdown {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                background-image: url(../../img/app/icons/down.svg);
                width: 16px;
                height: 16px;
                right: 6px;
                top: 11px;
                z-index: 9;
            }
        }

        .c-input-wide__input {
            width: 100%;
            border-color: #D3D4D5;
            height: 40px;
            appearance: none;

            &:focus-visible {
                outline: none;
            }
        }

        select {
            border-color: #D3D4D5;
            height: 40px;

            option {
                color: #5A5A5A;
                font-size: 15px;
                line-height: 18.86px;
                font-weight: 600;
            }

            &:focus-visible {
                outline: none;
            }
        }

        textarea {
            border-color: #D3D4D5;
            min-height: 120px;

            &:focus-visible {
                outline: none;
            }
        }
    }

}

.mb-30 {
    margin-bottom: 30px;
}

.tag-notification {
    background-color: #4AA49D1A;
    padding: 18px;
    display: flex;
    align-items: start;

    p {
        margin-left: 11px;
        margin-bottom: 0;
        font-size: 16px;
        color: #5A5A5A;
        font-weight: 600;
        line-height: 22.5px;
    }

    span {
        display: block;
        font-size: 15px;
        color: #5A5A5A;
        font-weight: 400;
        line-height: 22.5px;
    }
}


// Custom select dropdown

.custom-select-dropdown {
    position: relative;

    .dropdown-button {
        button {
            border: 1px solid #D3D4D5;
            width: 100%;
            height: 40px;
            text-align: left;
            font-size: 15px;
            font-weight: 600;
            padding: 7px 25px 7px 7px;
            line-height: 18.86px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                background-image: url(../../img/app/icons/down.svg) !important;
                width: 16px;
                height: 16px;
                right: 6px;
                top: 10px;
                z-index: 9;
            }
        }


    }

    .custom-option {
        background-color: #f1f1f1;
        overflow-x: auto;
        max-height: 300px;
        padding-left: 10px;

        .sub-title-dropdown {
            ul {
                width: 100%;
                overflow-x: auto;
                padding-left: 0;
                list-style: none;
                margin-bottom: 0;

                span {
                    &.label {
                        color: #b0afae;
                        font-size: 15px;
                        line-height: 22px;
                        font-weight: 600;
                        cursor: not-allowed;
                    }
                }

                li {
                    cursor: pointer;
                    color: #5A5A5A;
                    font-weight: 600;
                    padding: 5px 15px;
                }
            }
        }

        .sub-menu {
            ul {
                li {
                    &:hover {
                        background-color: #4AA49D1A;
                    }
                }
            }
        }
    }
}


.seleted {
    position: relative;
    background-color: #4AA49D1A;

    &:after {
        content: "";
        position: absolute;
        top: 13px;
        left: 0px;
        width: 5px;
        height: 9px;
        margin: 0px 4px;
        border-bottom: 2px solid #000000;
        border-right: 2px solid #000000;
        transform: rotate(45deg);

    }

}