/*------------------------------------*\ 
    modal  
\*------------------------------------*/

/*html*/
.l-modal-open {
  overflow: hidden;
  body {
    overflow: hidden;
  }
}
.c-modal {
  $class: c-modal;
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-modal;
  background: rgba(0, 0, 0, 0.76);
  overflow-y: auto;
  line-height: 26px;
  &__bg {
    width: 100%;
    height: 100%;
    position: fixed;
  }

  @keyframes loader {
    to {
      transform: rotate(360deg);
    }
  }
  &__loader {
    position: absolute;
    // top:50%;
    // left:50%;
    width: 64px;
    height: 64px;
    background: url("../../img/app/loader-white.svg") no-repeat center center / contain;
    animation: loader 1s steps(12, end) infinite;
  }

  &__close-modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    display: block;
    color: $color-grey-dark;
    padding: 0;
    font-size: 14px;
    line-height: 40px;
    &:before {
      content: $icon-cancel-thin;
      @include mav-icon-font;
    }
  }

  &__inner {
    text-align: center;
    transform: translateY(-40px);
    transition: transform 0.3s ease-in-out;
    margin: 60px auto;
    margin-top: 14vh; //154px
    padding: 35px 25px;
    @media (min-width: 600px) {
      padding: 35px 30px;
    }
    background: $color-grey-light;
    border: 1px solid #bcbec0;
    max-width: 600px;
    width: calc(100% - 50px);
    position: relative;

    *:last-child {
      margin-bottom: 0;
    }
  }

  &__inner-mav-select {
    text-align: left;
    background-color: #ffffff;
    max-width: 900px;
  }

  &__title {
    @include font-bold;
    font-size: 16px;
  }

  &__copy {
    margin-bottom: 20px;
  }

  &__form {
    width: 100%;
    margin-bottom: 20px;
    border: 0;
    textarea {
      width: 100%;
    }
  }

  &__small-print {
    font-size: 10px;
    margin-top: -5px;
    text-decoration: underline;
  }

  &--display-block {
    display: block;
  }
  &--visible {
    opacity: 1;
    display: block;
    .c-modal__inner {
      transform: translateY(0);
    }
  }

  &--loader {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &--hide-close-icon {
    .#{$class}__close-modal {
      display: none;
    }
  }

  &--max-width-400 .c-modal__inner {
    max-width: 400px;
  }
  &--max-width-500 .c-modal__inner {
    max-width: 500px;
  }
  &--max-width-700 .c-modal__inner {
    max-width: 700px;
  }
  &--max-width-800 .c-modal__inner {
    max-width: 800px;
  }
  &--max-width-920 .c-modal__inner {
    max-width: 920px;
  }
  &--max-width-1020 .c-modal__inner {
    max-width: 1020px;
  }

  &--max-width-80 .c-modal__inner {
    max-width: 80%;
  }
  &--max-width-90 .c-modal__inner {
    max-width: 90%;
  }
}

.styleguide .c-modal {
  position: relative;
  height: 500px;
  .c-modal__bg {
    position: absolute;
  }
  .c-modal__inner {
    margin-top: 100px;
  }
}

.demopage .c-modal {
  position: absolute;
  height: 100%;
  .c-modal__bg {
    position: absolute;
  }
}

/*doc
---
title: modal
name: modal
category: Components
---

If the small print below the back button is not needed, just remove it fomr the dom.

```html_example
	<div class="c-modal c-modal--visible">
		<div class="c-modal__bg js-close-modal"></div>
		<div class="c-modal__inner">
			<button class="c-modal__close-modal js-close-modal">
				<span class="u-visually-hidden">close modal</span>
			</button>
			<p class="c-modal__title">Sorry, you didn’t get this one</p>
			<p class="c-modal__copy">You forgot to put your password in. Please enter your password to complete sign in.</p>
			<button class="c-btn c-btn--v-small c-btn--teal u-after-icon-angle-right u-margin-right-0 js-close-modal">Back</button>
			<p class="c-modal__small-print">Dont show me this message again</p>
		</div>
	</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-modal--hide-close-icon</button>
    </div>
</div>

Full screen modal loader (note the c-modal element has a modifier).

```html_example
	<div class="c-modal c-modal--loader c-modal--visible">
		<div class="c-modal__loader js"></div>
	</div>
```

*/

.conversation-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .c-subheader-info-panel {
    width: 100%;
  }

  .c-btn {
    margin-bottom: 0px;
    padding: 10px 15px 9px 15px;
    width: 10vh;
    font-weight: bolder
  }
}