.l-content-width {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    // padding-right: sp(40);
    // padding-left: sp(40);
    width: calc(100% - 50px);
    &--small {
        max-width: 600px;        
    }  
    
}


/*----------- sticky footer -------------*/
#main {
    height:100%;
    > div[data-reactroot] {
        height:100%;
        display: flex;
        flex-direction: column;
    }
}  

.l-sticky-footer-wrap {
    // min-height: 100%;
    // /* equal to footer height */
    // // margin-bottom: -400px;
    // &__spacer {
    //     content: '';
    //     display: block;
    //     // height:400px;
    //     visibility: hidden;
    // }
  flex: 1 0 auto; 

}

.l-main-content-padding {
    padding-top: sp(32);
    padding-bottom: sp(32);
    @media (min-width: 720px) {
        padding-top: sp(64);
        padding-bottom: sp(64);
    }
}

/*----------- layout specifc to app or web versions -------------*/
/*html*/.l-web {}

/*html*/.l-app {}


.l-no-top-nav .l-sticky-footer-wrap {
    padding-top: 0;
    .c-top-nav {display: none;}
}

.l-no-bottom-nav .l-sticky-footer-wrap {
    padding-bottom: 0; 
    .c-bottom-nav {display: none;}
}

.l-no-header .c-site-header { position: fixed; left: 200%; height: 0; width: 0;}
.l-no-footer .c-site-footer { position: fixed; left: 200%; height: 0; width: 0;}

