//Variables are commented in as they are implemented

/*------------------------------------*\ 
    Font variables
\*------------------------------------*/
$base-font-size: 16px;
$base-line-height: 26px;
$base-font-color: rgb(90, 90, 90);

// Icon Variables - font awesome
$icon-angle-down: "\f107";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-cancel: "\e80a";
$icon-cancel-thin: "\e809";

//Icons object used to generate utility classes
$icons: icon-angle-down "\f107", icon-angle-left "\f104", icon-angle-right "\f105";

/*------------------------------------*\ 
    Spacing variables
\*------------------------------------*/
// vaguely fibonacci
// $sp-xs: 6px;
// $sp-s: 12px;
// $sp-m: 28px;
// $sp-l: 36px;
// $sp-xl: 60px;
// $sp-xxl: 96px;

// $base-spacing-unit: $sp-m;

// 8 point grid system
$sp-xxxs: 4px;
$sp-xxs: 4px;
$sp-xs: 8px;
$sp-s: 16px;
$sp-m: 24px;
$sp-l: 32px;
$sp-xl: 40px;
$sp-xxl: 48px;
$sp-xxxl: 56px;

$spacing: 0 0px, xs 8px, s 16px, sm 22px, m 24px, l 32px, xl 40px, xxl 48px, xxxl 56px;

$base-spacing-unit: $sp-m;
$base-vertical-spacing: $sp-s;
$base-vertical-spacing-smaller: 12px;

/*------------------------------------*\ 
    Media query variables
\*------------------------------------*/
$xs_bp: 540px;
$s_bp: 720px;
$m_bp: 980px;
$l_bp: 1080px;
$xl_bp: 1200px;
$xxl_bp: 1440px;

// same as above - used in mixins as object
$breakpoints: xs 540px, s 720px, m 980px, l 1080px, xl 1200px, xxl 1440px;

//Shared media query variables
$desktop-bp: 4000px;

/*------------------------------------*\ 
    Color palette
\*------------------------------------*/
$color-green: rgb(43, 182, 115);
$color-teal: rgb(0, 167, 157);
$color-yellow: rgb(255, 204, 109);
//gradients
// $color-gradient-1-ltr:  linear-gradient(to right, $color-green, rgb(18,168,158));
// $color-gradient-1-ttb:  linear-gradient(to bottom, $color-green, rgb(18,168,158));
$color-gradient-1-ltr: linear-gradient(to right, $color-green, $color-teal);
$color-gradient-1-ttb: linear-gradient(to bottom, $color-green, $color-teal);

//orange
$color-orange: rgb(246, 138, 58); //#f68a3b
$color-orange-light: lighten($color-orange, 20%);
$color-orange-dark: darken($color-orange, 10%);

//red
$color-red: rgb(239, 83, 80); //ef5350
$color-red-light: #f7a5a4;
// $color-red-light: lighten($color-red, 10%);
$color-red-dark: darken($color-red, 10%);

//green
$color-1: $color-green;
$color-1-light: lighten($color-1, 39.5%); //orange
$color-1-dark: darken($color-1, 10%);
// $color-1-light:  lighten(saturate(adjust-hue($color-1, 20.9016), 12.4402), 38.6275);

//fb-blue
$color-fb-blue: #4065b5; //ef5350

//teal
$color-2: $color-teal;
$color-2-light: lighten($color-2, 50%);
$color-2-dark: darken($color-2, 10%);

//yellow
$color-3: $color-yellow;
$color-3-light: lightorange;

$color-black-brown: #1d1c1a;

$color-4: #ac2f2f;
$color-4-light: pink;

$black: #000000;
$black-light: lighten($black, 30%);
$white: #ffffff;
$white-dark: darken($white, 10%);

// text colors
$color-text-dark: rgb(29, 29, 27);
$color-text-light: rgb(186, 186, 186);
$color-text-v-light: rgb(221, 221, 221);

// greys
$color-dark-grey: rgb(81, 84, 87);
$color-light-grey: rgb(237, 238, 240);

$color-grey-xdark: rgb(81, 84, 87);
$color-grey-mdark: rgb(118, 124, 129);
$color-grey-dark: #afafaf;
$color-grey-mid: #d1d3d4; //rgb(236, 239, 239)
$color-grey-mid-light: #d0d6d6;
$color-grey-mid-light-light: #d7dfdf;
$color-grey-light: #ecefef;
$color-grey-xlight: #f1f1f1;
$color-grey-xxlight: #f6f6f6;
$color-grey-xxxlight: #fbfcfc;

$color-grey-186: rgb(186, 186, 186);

$color-grey-xdark: rgb(81, 84, 87); //
$color-grey-dark: #afafaf;
$color-grey-mid: #d1d3d4;
$color-grey-light: rgb(234, 234, 234); //
$color-grey-xlight: #edeef0;
$color-grey-xxlight: #f6f6f6;
$color-grey-xxxlight: #fbfcfc;

// chaining variable names
$color-highlight: $color-1;

// text input and validation colors
$color-active: $color-1; //orange
$color-hover: $color-1; //orange
$color-invalid: $color-4; //red
$color-valid: $color-2; // green
$color-empty: $color-3; //yellow

$color-input-border: rgb(90, 90, 90);

//yellow
$color-yellow: rgb(255, 204, 109);
$color-yellow-light: lighten($color-yellow, 20%);
$color-yellow-dark: darken($color-yellow, 10%);

//green
$color-green: rgb(43, 182, 115);
$color-green-light: lighten($color-green, 39.5%); //orange
$color-green-dark: darken($color-green, 10%);
// $color-green-light:  lighten(saturate(adjust-hue($color-green, 20.9016), 12.4402), 38.6275);

//teal
$color-teal: rgb(0, 167, 157);
$color-teal-light: lighten(desaturate(#00a79d, 47.48), 40);
$color-teal-dark: darken($color-teal, 10%);

//orange
$color-orange: rgb(246, 138, 58); //#f68a3b
$color-orange-light: lighten($color-orange, 20%);
$color-orange-dark: darken($color-orange, 10%);

//red
$color-red: rgb(239, 83, 80); //ef5350
$color-red-light: #f7a5a4;
// $color-red-light: lighten($color-red, 10%);
$color-red-dark: darken($color-red, 10%);

$color-red-2: #ed1c24; //ef5350

//fb-blue
$color-fb-blue: #4065b5; //ef5350

$black: #000000;
$black-light: lighten($black, 30%);
$white: #ffffff;
$white-dark: darken($white, 10%);

//gradients
$color-grad-horz-green-to-teal: linear-gradient(to right, $color-green, $color-teal);

$color-grad-horz-teal-to-green: linear-gradient(to right, $color-teal, $color-green);

$color-grad-vert-green-to-teal: linear-gradient(to bottom, $color-green, $color-teal);

$color-grad-vert-teal-to-green: linear-gradient(to bottom, $color-teal, $color-green);

// greys
$color-dark-grey: rgb(81, 84, 87);
$color-light-grey: rgb(237, 238, 240);

$color-grey-xdark: rgb(81, 84, 87);
$color-grey-dark: #afafaf;
$color-grey-mid: #d1d3d4; //rgb(236, 239, 239)
$color-grey-light: #ecefef;
$color-grey-xlight: #f1f1f1;
$color-grey-xxlight: #f6f6f6;
$color-grey-xxxlight: #fbfcfc;

$color-grey-186: rgb(186, 186, 186);

// chaining variable names
$color-highlight: $color-green;

// text input and validation colors
$color-active: $color-green; //orange
$color-hover: $color-green; //orange
$color-invalid: $color-red; //red
$color-valid: $color-teal; // green
$color-empty: $color-yellow; //yellow

$color-input-border: rgb(90, 90, 90);

/*------------------------------------*\ 
    Color palette NEW
\*------------------------------------*/

//yellow
$color-yellow: rgb(255, 204, 109);
$color-yellow-light: lighten($color-yellow, 20%);
$color-yellow-dark: darken($color-yellow, 10%);

//green
$color-green: rgb(43, 182, 115);
$color-green-light: lighten($color-green, 39.5%); //orange
$color-green-dark: darken($color-green, 10%);
// $color-green-light:  lighten(saturate(adjust-hue($color-green, 20.9016), 12.4402), 38.6275);

//teal
$color-teal: rgb(0, 167, 157);
$color-teal-light: lighten(desaturate(#00a79d, 47.48), 40);
$color-teal-dark: darken($color-teal, 10%);

//orange
$color-orange: rgb(246, 138, 58); //#f68a3b
$color-orange-light: lighten($color-orange, 20%);
$color-orange-dark: darken($color-orange, 10%);

//red
$color-red: rgb(239, 83, 80); //ef5350
$color-red-light: #f7a5a4;
// $color-red-light: lighten($color-red, 10%);
$color-red-dark: darken($color-red, 10%);

$color-red-2: #ed1c24; //ef5350

//fb-blue
$color-fb-blue: #4065b5; //ef5350

$black: #000000;
$black-light: lighten($black, 30%);
$white: #ffffff;
$white-dark: darken($white, 10%);

//gradients
$color-grad-horz-green-to-teal: linear-gradient(to right, $color-green, $color-teal);

$color-grad-horz-teal-to-green: linear-gradient(to right, $color-teal, $color-green);

$color-grad-vert-green-to-teal: linear-gradient(to bottom, $color-green, $color-teal);

$color-grad-vert-teal-to-green: linear-gradient(to bottom, $color-teal, $color-green);

// greys
$color-dark-grey: rgb(81, 84, 87);
$color-light-grey: rgb(237, 238, 240);

$color-grey-xdark: rgb(81, 84, 87);
$color-grey-dark: #afafaf;
$color-grey-mid: #d1d3d4; //rgb(236, 239, 239)
$color-grey-light: #ecefef;
$color-grey-xlight: #f1f1f1;
$color-grey-xxlight: #f6f6f6;
$color-grey-xxxlight: #fbfcfc;

$color-grey-186: rgb(186, 186, 186);

// chaining variable names
$color-highlight: $color-green;

// text input and validation colors
$color-active: $color-green; //orange
$color-hover: $color-green; //orange
$color-invalid: $color-red; //red
$color-valid: $color-teal; // green
$color-empty: $color-yellow; //yellow

$color-input-border: rgb(90, 90, 90);

/*------------------------------------*\ 
    z-index table
\*------------------------------------*/
/* 5 */
$z-progress: 5;
/* 10 */
$z-hero: 10;
/* 20 */
$z-bio: 20;
/* 30 */
$z-menu: 30;
/* 40 */
$z-header: 40;
/* 40 */
$z-footer: 40;
/* 50 */
/* 60 */
/* 70 */
/* 80 */
$z-alert: 80;
$z-dev: 90;
/* 100 */
$z-modal: 100;

/*------------------------------------*\ 
    reusuable style variables 
\*------------------------------------*/
// $border-grey: 1px solid #d1d3d4;
$box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
$box-shadow-small: 0 5px 10px rgba(0, 0, 0, 0.3);
$border-grey: 2px solid #d1d3d4;
$border-grey-tab: #e8e8e8;

$base-radius: 6px;

/*------------------------------------*\
    base64 images
\*------------------------------------*/
$img-arrow-right: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4xLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNyAxMDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI3IDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+DQo8cmVjdCBmaWxsPSIjRjFGMUYxIiB3aWR0aD0iMjciIGhlaWdodD0iMTAwIi8+DQo8cG9seWdvbiBmaWxsPSIjRThFOEU4IiBwb2ludHM9IjEsMTAwIDAsMTAwIDAsMCAxLDAgMjYsNTAgIi8+DQo8L3N2Zz4NCg==";
$img-arrow-down: "data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPHJlY3QgZmlsbD0iI0YxRjFGMSIgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiLz4NCjxwb2x5Z29uIGZpbGw9IiNFOEU4RTgiIHBvaW50cz0iMCwzLjcgMCwwIDEwMCwwIDEwMCwzLjcgNTAsOTYuMyAiLz4NCjwvc3ZnPg0K";

/*------------------------------------*\ 
    component variables
\*------------------------------------*/
// these variables are shared across several components.
// if they were only relative to one component, they should be kept in the scope of that component

// header height variables - used both in the header component and the body top padding
// $header-height-mobile: 60px;
// $header-height-desktop: 140px;

$icon-space: "\00a0";
$icon-angle-down: "\e800";
$icon-angle-left: "\e801";
$icon-angle-right: "\e802";
$icon-angle-up: "\e803";
$icon-avatar-fill: "\e804";
$icon-avatar: "\e805";
$icon-calendar: "\e806";
$icon-camera: "\e807";
$icon-cancel-circle: "\e808";
$icon-cancel: "\e80a";
$icon-cancel-thin: "\e809";
$icon-check: "\e80b";
$icon-cog: "\e80c";
$icon-exclamation: "\e81b";
$icon-info: "\e81c";
$icon-list: "\e80d";
$icon-location-arrow: "\e80e";
$icon-location: "\e80f";
$icon-pencil: "\e810";
$icon-plus-circle: "\e811";
$icon-plus: "\e812";
$icon-refresh: "\e813";
$icon-search: "\e814";
$icon-star-empty: "\e815";
$icon-star-full: "\e816";
$icon-van-check: "\e817";
$icon-van-fill: "\e818";
$icon-van-plus: "\e819";
$icon-van: "\e81a";
$icon-gallery: "\e81d";
$icon-dot: "\f111";
$icon-dot-hollow: "\e81e";
$icon-pause: "\e81f";
$icon-play: "\e820";
$icon-stop: "\e821";
$icon-calendar2: "\e823";
$icon-clock: "\e824";

$icons: icon-angle-down "\e800", icon-angle-left "\e801", icon-angle-right "\e802", icon-angle-up "\e803",
  icon-avatar-fill "\e804", icon-avatar "\e805", icon-calendar "\e806", icon-camera "\e807", icon-cancel-circle "\e808",
  icon-cancel "\e80a", icon-cancel-thin "\e809", icon-check "\e80b", icon-cog "\e80c", icon-exclamation "\e81b",
  icon-info "\e81c", icon-list "\e80d", icon-location-arrow "\e80e", icon-location "\e80f", icon-pencil "\e810",
  icon-plus-circle "\e811", icon-plus "\e812", icon-refresh "\e813", icon-search "\e814", icon-star-empty "\e815",
  icon-star-full "\e816", icon-van-check "\e817", icon-van-fill "\e818", icon-van-plus "\e819", icon-van "\e81a",
  icon-gallery "\e81d", icon-dot "\f111", icon-dot-hollow "\e81e", icon-van-plus "\e819", icon-pause "\e81f",
  icon-play "\e820", icon-stop "\e821", icon-calendar2 "\e823", icon-clock "\e824";
