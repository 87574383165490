.messages-list-section {
  height: 70vh;
  overflow: scroll;

  .msg-wrapper {
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    gap: 12px;
    margin: 2px 0;
    justify-content: space-between;
    padding: 16px 0;
    a {
      color: $color-teal !important;
      text-decoration: none;
    }
  }
  .msg-header {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 12px;
    span {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    p {
      margin-bottom: 0;
    }
  }
  .msg-body {
    padding: 20px;
    background-color: $color-grey-xxlight;
  
    p {
      margin-bottom: 0;
      color: rgba(0, 0, 0, 1);
      line-height: 22.4px;
    }
  }
  .unread-msg-body {
    border: 2px solid #34b575;
  }
  .msg-outer {
    width: 100%;
  }
  .msg-header-left {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .msg-header-right {
    p {
      display: flex;
      gap: 4px;
    }
  }
  .view-btn {
    flex-shrink: 0;
    .c-btn--small {
      margin: 0;
      margin-top: 40px;
    }
  }
}

.bulk-read-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 23px 0px;
}

.select-all-checkbox {
  display: flex;
  align-items: center;
}

.select-all-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.select-all-checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.86px;
  color: #5a5a5a;
  display: flex;
  align-items: center;
}

.select-all-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.select-all-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #4aa49d;
}

.btn-bulk-read-before {
  height: 36px;
  padding: 9px 14px;
  border: none;
  font-size: 14px;
  line-height: 17.6px;
  align-items: center;
  font-weight: 600;
  width: 162px !important;
  background: #f1f1f1 !important;
  color: #6e6e6e !important;
}
.btn-bulk-read-after {
  height: 36px;
  padding: 9px 14px;
  border: none;
  font-size: 14px;
  line-height: 17.6px;
  align-items: center;
  font-weight: 600;
  width: 105px;
  background: #4aa49d;
  color: #ffffff;
}

.conversation-body {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
}

.conversation-section {
  width: 100% !important;
  padding: 15px 50px !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 74vh;
  overflow: scroll;
}
.user-conversation-section {
  height: 66vh;
}
.selected-msg-wrapper {
  background-color: rgba(74, 164, 157, 10%);
}