/* Basic resets and styling */

.ban-modal-wrapper {
  padding: 10px;
}

.open-modal-btn {
  background-color: teal;
  color: white;

  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ban-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ban-modal-content {
  background-color: #edefef;
  width: 600px;
  height: 431px;
  overflow: hidden;
  position: relative;
  padding: 32px;

  margin-bottom: 16px;
}

.ban-modal {
  height: 82px;
  width: 536px;
  margin-bottom: 12px;
}

.ban-modal h2 {
  margin: 0 0 10px 0;
  font-size: 24px;
  height: 30px;
  width: 536px;
  color: #000000;
  display: flex;
  justify-content: center;
  font-weight: 600;
 
}

.ban-modal p {
  width: 472px;
  height: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  padding-left: 30px;
}

.ban-modal-job {
  width: 536px;
  height: 180px;
  margin-top: 12px;
}
.job-card {
  border: 1px solid #ddd;
  width: 536px;
  height: 84px;
  padding: 10px;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

}

.job-details {
  font-size: 14px;
  color: #000000;


}

.view-btn-ban {
  background: #00a79d;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 41px;
  padding: 11px 20px 12px 20px;
  color: #fff;
}

.ban-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 38px;

}

.cancel-btn,
.confirm-btn {
  font-weight: 600;
  font-size: 14px;
  width: 120px;
  height: 41px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-btn {
  background: #edefef;
  border: 1px solid #000;
}

.confirm-btn {
  background: #fb2929;
  font-size: 14px;
  color: #fff;
}
