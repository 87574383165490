// @font-face {
//   font-family: "font-name";
//   font-weight: normal;
//   font-style: normal;
//   src: url("../font/font-name.eot");
//   src: url("../font/font-name.eot?#iefix") format("embedded-opentype"), url("../font/font-name.woff") format("woff"), url("../font/font-name.ttf") format("truetype"), url("../font/font-name.svg#u-font") format("svg");
//
// }

// @font-face {
//     font-family: "geo-black";
//     font-weight: normal;
//     font-style: normal;
//     src: url("../../dist/fonts/geomanist-black-webfont.woff") format("woff");
// }

@font-face {
  font-family: "galano";
  src: url("../../fonts/galanogrotesquedemo-bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "mav-icons";
  src: url("../../fonts/mav-icon-font/font/mav-icons.eot?892051");
    src: url("../../fonts/mav-icon-font/font/mav-icons.eot?892051#iefix") format("embedded-opentype"),
      url("../../fonts/mav-icon-font/font/mav-icons.woff2?892051") format("woff2"),
      url("../../fonts/mav-icon-font/font/mav-icons.woff?892051") format("woff"),
      url("../../fonts/mav-icon-font/font/mav-icons.ttf?892051") format("truetype"),
      url("../../fonts/mav-icon-font/font/mav-icons.svg?892051#mav-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin mav-icon-font() {
  font-family: "mav-icons";
  font-weight: normal;
}

@mixin font-galeno() {
  font-family: "galano";
  font-weight: normal;
}

.u-font-galeno {
  @include font-galeno;
}

// font mixins for cleaner font managment
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-light {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  @include font-smoothing;
}
@mixin font-regular {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  @include font-smoothing;
}

@mixin font-semi-bold {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  @include font-smoothing;
}

@mixin font-bold {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  @include font-smoothing;
}

@mixin font-lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  @include font-smoothing;
}

.u-font-light {
  @include font-light;
}

.u-font-regular {
  @include font-regular;
}

.u-font-semi-bold {
  @include font-semi-bold;
}

.u-font-bold {
  @include font-bold;
}

.u-font-lato-light {
  @include font-lato-light;
}

html,
.u-p {
  @include font-size(15px);
  @include font-regular;
  color: $base-font-color;
  // letter-spacing: 0.01em;
  @media (min-width: 600px) {
    // @include font-size(16px);
  }
}
.u-p {
  /*margin-bottom: $base-vertical-spacing;*/
}

.u-larger-body-font {
  // @include font-size(16px);
  @media (min-width: 600px) {
    @include font-size(18px);
  }
}

/*IE9 + 10 doesnt like shorthand font delcrations with rems*/
body {
  // font: 17px/1.7 sans-serif;
  // @include font-size(16px);
}

h1,
.u-h1 {
  @include font-size(28px);
  @media (min-width: 600px) {
    @include font-size(32px);
  }
}

h2,
.u-h2 {
  @include font-size(28px);
}

h3,
.u-h3 {
  @include font-size(24px);
}

h4,
.u-h4 {
  @include font-size(20px);
}

h5,
.u-h5 {
  @include font-size(18px);
}

h6,
.u-h6 {
  @include font-size(16px);
}

h1,
.u-h1,
h2,
.u-h2,
h3,
.u-h3,
h4,
.u-h4,
h5,
.u-h5,
h6,
.u-h6 {
  @include font-lato-light;
  line-height: 1.4;
}

// p {
//     font-size: 1.4em;
// }

a {
  @include font-regular;
  color: $color-highlight;
  text-decoration: underline;
  text-decoration-skip: ink;
}

p {
  strong,
  b {
    @include font-regular;
    color: $color-highlight;
  }
}

/* font sizes:
18pt - input erorr message, small pring on modal
24pt - list item smaller text, nav bar
28pt - list items
30pt - avatar info
32pt - input labels
36pt - large body text
42pt - buttons
50pt - titles
*/

$font-sizes: 9 9, 10 10, 12 12, 13 13, 14 14, 15 15, 16 16, 18 18, 20 20, 21 21, 24 24, 25 25;

// utility class generator
@each $size in $font-sizes {
  $key: nth($size, 1);
  $value: nth($size, 2);

  .u-font-size-#{$key} {
    font-size: $value + px !important;
  }
}

// h1, h2, h3, h4, p {
//     a {
//         font-size: 1em;
//     }

// }

/*list styling*/
// ul {
//     list-style-position: inside;
//     list-style-type: disc;
//     margin-top: 0;
//     padding-left: 0;

//     li {
//         ul {
//             margin-left: 1em;
//             margin-bottom: 0;
//         }

//     }

// }

/*doc
---
title: Typography
name: type
category: typography
---


```html_example
<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<p>Lorem <a href="">ipsum dolor sit</a> amet, consectetur adipisicing elit. Magni sunt dolores vitae libero quasi incidunt quas, quos, voluptates repellat facilis, optio, <span class="u-text-highlight">praesentium assumenda earum?</span> Sit at maxime, necessitatibus voluptates. Ratione.</p>
<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni sunt dolores vitae libero quasi incidunt quas, quos, voluptates repellat facilis, optio, praesentium assumenda earum? Sit at maxime, necessitatibus voluptates. Ratione.</p>
<h4>Heading 2</h4>
<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni sunt dolores vitae libero quasi incidunt quas, quos, voluptates repellat facilis, optio, praesentium assumenda earum? Sit at maxime, necessitatibus voluptates. Ratione.</p>
                    
```
*/
