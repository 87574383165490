/*------------------------------------*\ 
    buttons    
\*------------------------------------*/
@mixin button-text-color-styles {
  // extra spcificty to overwrite color
  &.c-btn--black-text {
    color: $black;
    &:hover,
    &:focus {
      color: $black;
    }
    
  }

  &.c-btn--green-text {
    color: $color-green;
    &:hover,
    &:focus {
      color: $color-green;
    }
  }

  &.c-btn--teal-text {
    color: $color-teal;
    &:hover,
    &:focus {
      color: $color-teal;
    }
  }

  &.c-btn--client-green-driver-teal-text {
    color: $color-green;
    &:hover,
    &:focus {
      color: $color-green;
    }
    @at-root .user-driver .c-btn--client-green-driver-teal-text {
      color: $color-teal;
      &:hover,
      &:focus {
        color: $color-teal;
      }
    }
  }
}

@mixin base-button-styles {
  display: inline-block;
  padding: 12px 44px;
  // padding: 12px 20px;
  min-width: 90px;
  text-decoration: none;
  @include font-semi-bold;
  @include font-size(20px, 1.3);
  margin-bottom: $base-vertical-spacing;
  transition: background-color 0.2s, color 0.2s, transform 0.2s, box-shadow 0.2s;
  border: none;
  text-align: center;
  cursor: pointer;
  vertical-align: top;
  // text-transform: capitalize;
  margin-right: 5px;
  // box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  &:hover,
  &:focus {
    // box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    // transform: translateY(-3px);
  }

  &--full-width {
    width: 100%;
    max-width: none;
    display: block;
    margin-right: 0;
    padding-left: 12px;
    padding-right: 12px;
  }

  &--v-small {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    min-width: 0;
    border-width: 1px;
  }

  &--v-small-alt {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    min-width: 0;
    border-width: 1px;
    @include font-bold;
  }

  &--small {
    padding: 10px 15px;
    @include font-size(14px, 1.5);
    min-width: 0;
    border-width: 1px;
  }

  // &--large {
  //     @media (min-width:900px) {
  //         padding: 14px 32px;
  //         @include font-size(18px);
  //     }
  //     &.c-btn--full-width,&.c-btn-outline--full-width {
  //         padding-left: 16px;
  //         padding-right: 16px;
  //     }
  // }

  &--float-right {
    float: right;
    margin-right: 0;
  }

  // if button has icon class add margin
  &[class*="u-before-icon"] {
    &:before {
      margin-right: 0.3em;
    }
  }
  &[class*="u-after-icon"] {
    &:after {
      margin-left: 0.3em;
    }
  }

  @media (max-width: 540px) {
    &--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block;
    }
  }
}

.c-btn {
  @include base-button-styles;
  @include button-text-color-styles;
  color: white;
  background: $color-yellow;

  &--yellow {
    background: $color-yellow;
  }

  &--green {
    background: $color-green;
  }

  &--teal {
    background: $color-teal;
  }

  &--orange {
    background: $color-orange;
  }

  &--red {
    background: $color-red;
  }

  &--fb-blue {
    background: $color-fb-blue;
  }

  &--black {
    color: white;
    background: $black;
  }

  &--grey {
    background: $color-grey-186;
  }

  &--grey-dark {
    background: $color-grey-dark;
  }
  &--grey-mdark {
    background: $color-grey-mdark;
  }

  &--grey-xdark {
    background: $color-grey-xdark;
  }

  &--light-grey {
    background: $color-light-grey;
  }

  &--white {
    color: black;
    color: $color-text-dark;
    // @at-root .user-driver .c-btn--white {
    //     color: $color-teal;
    // }
    background: $white;
  }
}

.c-btn-outline {
  $borderWidth: 2px;
  @include base-button-styles;
  color: $color-yellow;
  background: none;
  border: $borderWidth solid currentColor;

  &--yellow {
    color: $color-yellow;
  }

  &--green {
    color: $color-green;
  }

  &--teal {
    color: $color-teal;
  }

  &--orange {
    color: $color-orange;
  }

  &--red {
    color: $color-red;
  }

  &--fb-blue {
    color: $color-fb-blue;
  }

  &--black {
    color: $black;
  }

  &--white {
    color: $white;
  }
}

/*------------------------------------*\
    button text
\*------------------------------------*/

.c-btn-text {
  text-decoration: underline;
  padding: 0;
  color: $color-green;
  position: relative;
  margin-bottom: $base-vertical-spacing;
  margin-right: 24px;
  cursor: pointer;

  // &:after {
  //     content: $icon-angle-right;
  //     // font-family: FontAwesome;
  //     @include mav-icon-font;
  //     position: absolute;
  //     right: -10px;
  //     transform: translateY(2px);
  // }
}

/*------------------------------------*\
    button icon
\*------------------------------------*/

.c-btn-icon {
  padding: 3px 0;
  margin-right: 20px;
  font-size: 12px;

  // &:before {
  //     margin-right: 6px;
  // }
  &[class*="u-before-icon"] {
    &:before {
      margin-right: 0.3em;
    }
  }
  &[class*="u-after-icon"] {
    &:after {
      margin-left: 0.3em;
    }
  }
  .c-input & {
    margin-top: 4px;
  }
  &--large {
    font-size: 18px;
  }
}

/*doc
---
title: button icon
name: z-button-icon
category: Buttons
---


```html_example
    <button class="c-btn-icon u-before-icon-location-arrow u-color-green">Use current location</button>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-icon--large</button>
    </div>
</div>      
*/

/*doc
---
title: Buttons
name: buttons
category: Buttons
---

Default color for buttons is yellow, but the modifier exists also.   
As you can see from the designs the standard yellow button has black text, use the black text modifier and yellow modifier together to get this. Black text is not default in the yellow modifier as smaller button components use white text.

```html_example_table
    <button class="c-btn c-btn--black-text">Button</button>

    <button class="c-btn c-btn--yellow c-btn--black-text">Button</button>

    <button class="c-btn c-btn--green">Button</button>

    <button class="c-btn c-btn--teal">Button</button>

    <button class="c-btn c-btn--orange">Button</button>
    
    <button class="c-btn c-btn--grey">Button</button>

    <button class="c-btn c-btn--red">Button</button>
    
    <button class="c-btn c-btn--fb-blue">Button</button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn c-btn--white">Button</button>
    
    <button class="c-btn c-btn--black">Button</button>

```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn--full-width</button>
    </div>
    <div>
        <button>c-btn--small</button>
        <button>c-btn--v-small</button>
    </div>
    <div>
        <button>c-btn--black-text</button>
        <button>c-btn--green-text</button>
        <button>c-btn--teal-text</button>
        <button>c-btn--client-green-driver-teal-text</button>
    </div>
</div>
*/

/*doc
---
title: Buttons Outline
name: buttons-outline
category: Buttons
---

Default color for buttons is yellow, but the modifier exists also.


```html_example_table
    <button class="c-btn-outline">Button</button>

    <button class="c-btn-outline c-btn-outline--yellow">Button</button>

    <button class="c-btn-outline c-btn-outline--green">Button</button>

    <button class="c-btn-outline c-btn-outline--teal">Button</button>

    <button class="c-btn-outline c-btn-outline--orange">Button</button>

    <button class="c-btn-outline c-btn-outline--red">Button</button>
    
    <button class="c-btn-outline c-btn-outline--fb-blue">Button</button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn-outline c-btn-outline--white">Button</button>
    
    <button class="c-btn-outline c-btn-outline--black">Button</button>

```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-outline--full-width</button>
    </div>
    <div>
        <button>c-btn-outline--small</button>
        <button>c-btn-outline--v-small</button>
    </div>
</div>
*/

/*doc
---
title: Button text
name: buttontext
category: Buttons
---

```html_example_table
<button type="button" class="c-btn-text u-text-align-left">Learn more ></button>

```


*/

/*doc
---
title: Button text
name: buttontext
category: Buttons
---

```html_example_table
<button type="button" class="c-btn-text u-text-align-left">Learn more ></button>

```


*/

/*------------------------------------*\
    button pair
\*------------------------------------*/

.c-button-pair {
  $class: c-button-pair;
  display: flex;
  justify-content: space-between;
  min-height: 74px;
  margin-bottom: $base-vertical-spacing-smaller;
  .c-btn,
  .c-btn-outline {
    flex: 0 0 auto;
    width: calc(50% - 5px);
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    // border-width: 2px;
    margin-bottom: 0;
    margin-right: 0;
  }
}

/*doc
---
title: button pair
name: xbutton-pair
category: buttons
---

```html_example
<div class="c-button-pair">
    <button class="c-btn-outline c-btn-outline--red">lorem</button>
    <button class="c-btn c-btn--green">Select date<br/>and time</button> 
</div>

```

*/
