// TODO categorise rules

/*------------------------------------*\
    Display Utilities
\*------------------------------------*/

.u-display-none {
  display: none;
}

@include responsiveClasses(u-display-none, display, none);

.u-display-block {
  display: block;
}

@include responsiveClasses(u-display-block, display, block);

.u-highlight {
  color: $color-highlight !important;
}

.u-highlight-bg {
  background: $color-highlight !important;
}

// for accessibilty purposes
.u-visually-hidden {
  @include visually-hidden;
}

.u-visibility-hidden {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: opacity 0.3s ease-in-out !important;
  transition-property: visibility, opacity !important;
}

.u-visibility-hidden-until-load {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: opacity 0.3s ease-in-out !important;
  transition-property: visibility, opacity !important;
}

.u-display-table {
  display: table !important;
}

.u-display-table-row {
  display: table-row !important;
}

.u-display-table-cell {
  padding-right: sp(6) !important;
  display: table-cell !important;
}

.u-uppercase {
  text-transform: uppercase !important;
}
.u-lowercase {
  text-transform: lowercase !important;
}

.u-line-through {
  text-decoration: line-through;
}

.u-no-wrap {
  white-space: nowrap !important;
}

.u-text-align-left {
  text-align: left !important;
}

.u-text-align-right {
  text-align: right !important;
}

.u-text-align-center {
  text-align: center !important;
}

.u-padding-0 {
  padding: 0 !important;
}

.u-text-strikethrough {
  text-decoration: line-through !important;
}
.u-text-underline {
  text-decoration: underline !important;
}

@each $space in $spacing {
  $key: nth($space, 1);
  $value: nth($space, 2);

  .u-padding-#{$key} {
    padding: $value !important;
  }
  .u-padding-left-right-#{$key} {
    padding-left: $value !important;
    padding-right: $value !important;
  }
  .u-padding-top-bottom-#{$key} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
  .u-padding-top-#{$key} {
    padding-top: $value !important;
  }
  .u-padding-right-#{$key} {
    padding-right: $value !important;
  }
  .u-padding-bottom-#{$key} {
    padding-bottom: $value !important;
  }
  .u-padding-left-#{$key} {
    padding-left: $value !important;
  }
}

.u-padding-left-25 {
  padding-left: 25%;
}
.u-padding-right-25 {
  padding-right: 25%;
}

@each $space in $spacing {
  $key: nth($space, 1);
  $value: nth($space, 2);

  .u-margin-#{$key} {
    margin: $value !important;
  }
  .u-margin-left-right-#{$key} {
    margin-left: $value !important;
    margin-right: $value !important;
  }
  .u-margin-top-bottom-#{$key} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }
  .u-margin-top-#{$key} {
    margin-top: $value !important;
  }
  .u-margin-right-#{$key} {
    margin-right: $value !important;
  }
  .u-margin-bottom-#{$key} {
    margin-bottom: $value !important;
  }
  .u-margin-left-#{$key} {
    margin-left: $value !important;
  }
}

.u-link-bare {
  text-decoration: none !important;
}

.u-link-natural {
  text-decoration: underline !important;
  color: inherit !important;
}

.u-text-decoration-underline {
  text-decoration: underline !important;
}

.u-link-obvious {
  text-decoration: underline !important;
}

.u-white-box {
  @include white-box;
}

.u-clearfix {
  @include clearfix;
}
.u-clear-both {
  clear: both;
}

/* TODO RFC - remove unneeded specificty below */

.u-bg-grey-very-light.u-bg-grey-very-light {
  background: $color-grey-xxlight !important;
}

.u-uppercase {
  text-transform: uppercase !important;
}

.u-float-right {
  float: right !important;
}

.u-float-left {
  float: left !important;
}

.u-cursor-pointer {
  cursor: pointer !important;
}

// /* Icons utility classes generated */

// @each $icon in $icons {
//     $key: nth($icon, 1);
//     $value: nth($icon, 2);

//     // for old use of u-icon-[name]
//     .u-#{$key} {
//         &:before {
//             font-family: FontAwesome;
//             content: $value;
//             display: inline-block;
//         }
//     }
//     .u-before-#{$key} {
//         &:before {
//             content: $value;
//             font-family: FontAwesome;
//             display: inline-block;
//         }
//     }
//     .u-after-#{$key} {
//         &:after {
//             content: $value;
//             font-family: FontAwesome;
//             display: inline-block;
//         }
//     }
// }

/*------------------------------------*\
    Icons Utilities
\*------------------------------------*/

@each $icon in $icons {
  $key: nth($icon, 1);
  $value: nth($icon, 2);

  .u-before-#{$key} {
    &:before {
      content: $value;
      @include mav-icon-font;
      display: inline-block;
    }
  }

  .u-before-padded-#{$key} {
    &:before {
      content: $value;
      @include mav-icon-font;
      display: inline-block;
      padding-right: 0.5rem;
    }
  }

  .u-after-#{$key} {
    &:after {
      content: $value;
      @include mav-icon-font;
      display: inline-block;
    }
  }
}

/*------------------------------------*\
    Color utility classes
\*------------------------------------*/

// gradients
.u-color-grad-1-ltr {
  background: $color-gradient-1-ltr;
}
.u-color-grad-1-ttb {
  background: $color-gradient-1-ttb;
}

.u-color-1 {
  color: $color-1 !important;
}

.u-color-1-bg {
  background-color: $color-1 !important;
}

.u-color-2 {
  color: $color-2 !important;
}

.u-color-2-bg {
  background-color: $color-2 !important;
}

.u-color-3 {
  color: $color-3 !important;
}

.u-color-3-bg {
  background-color: $color-3 !important;
}

.u-color-4 {
  color: $color-4 !important;
}

.u-color-4-bg {
  background-color: $color-4 !important;
}

.u-color-black {
  color: $black !important;
}

.u-color-black-bg {
  background-color: $black !important;
}

.u-color-black-brown {
  color: $color-black-brown !important;
}

.u-color-black-brown-bg {
  background-color: $color-black-brown !important;
}

.u-color-white {
  color: $white !important;
}

.u-color-white-bg {
  background-color: $white !important;
}

.u-color-grey-xdark {
  color: $color-grey-xdark !important;
}

.u-color-grey-xdark-bg {
  background-color: $color-grey-xdark !important;
}

.u-color-grey-dark {
  color: $color-grey-dark !important;
}

.u-color-grey-dark-bg {
  background-color: $color-grey-dark !important;
}

.u-color-grey-mid {
  color: $color-grey-mid !important;
}

.u-color-grey-mid-bg {
  background-color: $color-grey-mid !important;
}

.u-color-grey-light {
  color: $color-grey-light !important;
}

.u-color-grey-light-bg {
  background-color: $color-grey-light !important;
}

.u-color-grey-xlight {
  color: $color-grey-xlight !important;
}

.u-color-grey-xlight-bg {
  background-color: $color-grey-xlight !important;
}

.u-color-grey-xxlight {
  color: $color-grey-xxlight !important;
}

.u-color-grey-xxlight-bg {
  background-color: $color-grey-xxlight !important;
}

.u-color-grey-xxxlight {
  color: $color-grey-xxxlight !important;
}

.u-color-grey-xxxlight-bg {
  background-color: $color-grey-xxxlight !important;
}

.u-color-green {
  color: $color-green !important;
}
.u-color-green-light {
  color: $color-green-light !important;
}
.u-color-green-dark {
  color: $color-green-dark !important;
}
.u-color-green-bg {
  background-color: $color-green !important;
}
.u-color-green-light-bg {
  background-color: $color-green-light !important;
}
.u-color-green-dark-bg {
  background-color: $color-green-dark !important;
}

.u-color-grey-186 {
  color: $color-grey-186;
}

.u-color-grey-186-bg {
  background-color: $color-grey-186;
}

.u-base-font-color {
  color: $base-font-color !important;
}

.u-color-teal {
  color: $color-teal !important;
}
.u-color-teal-light {
  color: $color-teal-light !important;
}
.u-color-teal-dark {
  color: $color-teal-dark !important;
}
.u-color-teal-bg {
  background-color: $color-teal !important;
}
.u-color-teal-light-bg {
  background-color: $color-teal-light !important;
}
.u-color-teal-dark-bg {
  background-color: $color-teal-dark !important;
}

.u-color-yellow {
  color: $color-yellow !important;
}
.u-color-yellow-light {
  color: $color-yellow-light !important;
}
.u-color-yellow-dark {
  color: $color-yellow-dark !important;
}
.u-color-yellow-bg {
  background-color: $color-yellow !important;
}
.u-color-yellow-light-bg {
  background-color: $color-yellow-light !important;
}
.u-color-yellow-dark-bg {
  background-color: $color-yellow-dark !important;
}

.u-color-orange {
  color: $color-orange !important;
}
.u-color-orange-light {
  color: $color-orange-light !important;
}
.u-color-orange-dark {
  color: $color-orange-dark !important;
}
.u-color-orange-bg {
  background-color: $color-orange !important;
}
.u-color-orange-light-bg {
  background-color: $color-orange-light !important;
}
.u-color-orange-dark-bg {
  background-color: $color-orange-dark !important;
}

.u-color-red {
  color: $color-red !important;
}
.u-color-red-light {
  color: $color-red-light !important;
}
.u-color-red-dark {
  color: $color-red-dark !important;
}
.u-color-red-bg {
  background-color: $color-red !important;
}
.u-color-red-light-bg {
  background-color: $color-red-light !important;
}
.u-color-red-dark-bg {
  background-color: $color-red-dark !important;
}

.u-color-red-2 {
  color: $color-red-2 !important;
}
.u-color-red-2-bg {
  background-color: $color-red-2 !important;
}

.u-color-black {
  color: $black !important;
}

.u-color-black-bg {
  background-color: $black !important;
}

.u-color-white {
  color: $white !important;
}

.u-color-white-bg {
  background-color: $white !important;
}

.u-color-grey-xdark {
  color: $color-grey-xdark !important;
}

.u-color-grey-xdark-bg {
  background-color: $color-grey-xdark !important;
}

.u-color-grey-dark {
  color: $color-grey-dark !important;
}

.u-color-grey-dark-bg {
  background-color: $color-grey-dark !important;
}

.u-color-grey-mid {
  color: $color-grey-mid !important;
}

.u-color-grey-mid-bg {
  background-color: $color-grey-mid !important;
}

.u-color-grey-mid-light-bg {
  background-color: $color-grey-mid-light !important;
}

.u-color-grey-mid-light-light-bg {
  background-color: $color-grey-mid-light-light !important;
}

.u-color-grey-light {
  color: $color-grey-light !important;
}

.u-color-grey-light-bg {
  background-color: $color-grey-light !important;
}

.u-color-grey-xlight {
  color: $color-grey-xlight !important;
}

.u-color-grey-xlight-bg {
  background-color: $color-grey-xlight !important;
}

.u-color-grey-xxlight {
  color: $color-grey-xxlight !important;
}

.u-color-grey-xxlight-bg {
  background-color: $color-grey-xxlight !important;
}

.u-color-grey-xxxlight {
  color: $color-grey-xxxlight !important;
}

.u-color-grey-xxxlight-bg {
  background-color: $color-grey-xxxlight !important;
}

.u-color-grey-186 {
  color: $color-grey-186 !important;
}

.u-color-grey-186-bg {
  background-color: $color-grey-186 !important;
}

.u-overflow-auto {
  overflow: auto !important;
}

.u-word-wrap {
  @include word-wrap();
}

.u-ellipsis {
  @include ellipsis();
}

.u-show-on-web {
  display: none !important;
  @at-root .l-web .u-show-on-web {
    display: block !important;
  }
}

.u-show-on-app {
  display: none !important;
  @at-root .l-app .u-show-on-app {
    display: block !important;
  }
}