/*------------------------------------*\ 
    Settings & Tools
\*------------------------------------*/
@import "settings.variables";
@import "tools.mixins";
@import "tools.functions";

/*------------------------------------*\ 
    Generic
\*------------------------------------*/
@import "generic.normalize"; // usually loaded from cdn, but this is a local dependancy for app bundling
@import "generic.general";
@import "confirmdriverbanmodal";
@import "pagenotfound";

/*------------------------------------*\ 
    Elements
\*------------------------------------*/ 
@import "elements/elements.fonts";
// @import "elements/elements.quotes";
// @import "elements/elements.tables";
// @import "elements/elements.horizontal-rule";

/*------------------------------------*\ 
    Layout 
\*------------------------------------*/
@import "layout.wrappers";  
@import "layout.grid-system"; 

/*------------------------------------*\ 
    Components
\*------------------------------------*/
// @import "components/components.slider";
// @import "components/components.tab-system";
// @import "components/components.toggle-switch";
@import "components/components.buttons";
@import "components/components.input";
@import "components/components.gradient-panel";
@import "components/components.modal";
@import "components/components.to-separate";
@import "components/components.rangeslider";
@import "components/components.pushNotifications";
@import "components/components.messages";
 
/*------------------------------------*\ 
    Utilities & Fallbacks
\*------------------------------------*/
@import "utilities.general";
// @import "fallbacks.main";

/*------------------------------------*\ 
    Development assistance
\*------------------------------------*/
// browsersync indicator placed at bottom
#__bs_notify__ {
	position: fixed;
	bottom:0 !important; top: auto !important;
	height: 50px; border-radius: 0 !important;
}


/*------------------------------------*\ 
      Image overlay to ensure deisgn consistency
  \*------------------------------------*/
//   body { 
//     // height:4000px;
//     position: relative;
//     #main {
//         position: relative;
//         z-index:20;
//     }
//     &:before {
//         background: url('../img/mobile-1.png') top center no-repeat / 100% auto;
//         // content: '';
//         position: fixed;
//         top: 0;
//         left:0;
//         display: block;
//         width: 100%;
//         height: 100%;
//         filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(240deg) saturate(600%) contrast(1.5);
//         filter: grayscale(100%) brightness(69.8%) sepia(100%) hue-rotate(240deg) saturate(140%) contrast(1.4);
//         opacity: 0.6;
//         @media (min-width:760px) {
//             background: url('../../img/desktop.jpg') top center no-repeat / 100% auto;
//         }
//         // z-index: 200;
//     }
//     &.hide-bg-image:before {display: none;}
//     &.bg-z-index:before {z-index:200;}
//     &.bg-disable-filter:before {filter:none; opacity:1;}
//     &.bg-enable-guide main:before {opacity:1;}
//     &.button-position {
//         .dev-helpers {top:0;}
//     }
//     &.hide-helpers .dev-helpers {display: none;}
//     // main:before {
//     //     content: '';
//     //     display: block;
//     //     position: fixed;
//     //     top: 0;
//     //     left: 50%;
//     //     width: 1px;
//     //     height: 100%;
//     //     background: red;
//     //     z-index: 100000;
//     //     opacity:0;
//     // }
// }


// .dev-helpers {
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     z-index: 200;
//     height: 30px;
//     // display: none;

//     button {
//         background: grey;
//         color: white;
//         padding: 2px 6px;
//         margin-right: 10px;
//     }
// }





