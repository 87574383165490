/*------------------------------------*\ 
    text input    
\*------------------------------------*/

    @mixin input-icon {
        @include animate-prop(color, opacity);
        font-family: 'FontAwesome';
        display: block;
        position: absolute;
        right: 14px;
        top: 50% !important;
        transform: translateY(-50%);
        font-size: 1em !important;
        line-height: inherit;
        color: #d1d3d4;
        opacity: 1;
        /* TODO RFC existing style overwrites */
        width: auto;
        height: auto;
        left: auto;
        bottom: auto !important;        
        /*  */
    }

.c-input {
    $class: c-input;

    @include placeholder() {
        color:$color-text-v-light; 
    }
 
    @include font-regular;
    @include animate-prop(background, border, color);
    // designed style
    // $pad-right: 12px;
    // $pad-left: 66px;
    // padding:15px $pad-right 15px $pad-left;
    
    $pad-right: 12px;
    $pad-left: 12px;
    padding:23px $pad-right 7px $pad-left;

    // box-shadow: $box-shadow-grey;
    // color:rgb(90,90,90);
    border: 1px solid $color-input-border;
    position: relative;
    margin-bottom: $base-spacing-unit;
    display: block;
    // padding-right: $pad-right * 3;

    &__label {
        @include font-semi-bold;
        @include animate-prop(font-size, transform, line-height, top, color);
        font-size: 0.8em;
        text-transform: uppercase;
        display: block;
        color:$color-text-light;         
        line-height: 1.5;
        position: absolute;

        // designed style
        // padding: 2px;
        // top: 50%;
        // transform:translateY(-50%);
        // left: 12px;

        top: 2px; 
        left: $pad-left;  

    }

    &__input {
        // @include animate-prop(color);
        line-height: 1;
        width: 100%;
        max-width: 100%;
        border: none;
        background: none;
        outline: none;
        display: block;
        // color: $base-font-color;
        padding: 0;
        height: auto;
        // &:focus,
        // &:hover {
        //     background: none;
        //     color: inherit;
        // }
        
        &--textarea {
            line-height: 1.5;
            height: 5em;
        }
    }
    &--input-button {
        max-width: 352px;
        .#{$class}__input,
        .#{$class}__input-button {
            width: 50%;
            max-width: none;
        }
        .#{$class}__input {
            padding-right: 2em;
        }
        .#{$class}__input-button {
            @include font-semi-bold;
            float: left;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: #000;
            color: white;
            padding: 0.5em;
            text-align: center;
            text-transform: uppercase;
            margin: 0;
            opacity: 1;
            &:after {
                font-family: 'FontAwesome';
                display: inline-block;
                content: '\f002';
                margin-left: 3px;
            }
        }
        &:after {
            left: calc(50% - 28px);
            right: auto;
        }
    }

    @mixin icon-input-styles {
        padding-right: 40px;        
    }
    &--icon-mag {
        @include icon-input-styles;
        &:after {
            font-family: 'FontAwesome';
            content: '\f002';
            position: absolute;
            right: 10px;
            top:50%;
            transform: translateY(-50%);
            color: $color-1;
            // font-size: 16px;
            // @include font-size(34)
        }
    }

    &--dropdown.#{$class}--dropdown {
        position: relative;
        &:after {
            @include input-icon;
            content: '\f063';
            color: rgba(0, 0, 0, 0.5);
        }
        .c-input__dropdown {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        .c-input__hidden-option {
            display: none;
        }
        .c-input__dropdown-text {
            content: 'test';
            display: block;
            line-height: 1.35;
            span {
                visibility: hidden;
            }
        }
    }
    &__validation-hint,
    &__validation-message {
        @include font-semi-bold;
        position: absolute;
        padding: 5px;
        top: 100%;
        margin-top: 6px;
        margin-left: 10px;
        background: $color-3;
        color: white;
        display: none;
        z-index: 2;
        border-radius: 4px;
        font-size: 0.75em;
        &:before {
            content: '';
            display: block;
            margin-left: 16px;
            position: absolute;
            top: -14px;
            border: 8px solid $color-empty;
            border-color: transparent transparent $color-empty;
        }
    }
    &__validation-message {
        background: $color-invalid;
        &:before {
            border-color: transparent transparent $color-invalid;
        }
    }
    // label movement change
    &.is-active,
    &.is-filled {
        .c-input__label {
            font-size: 0.5em;
            transform: translate3d(0, 3px, 0);
            top: 0;
        }
    }
    // &:hover {
    //     background: $color-1-light;
    //     color: $color-hover;
    //     &:after {
    //         font-size: 1em;
    //     }
    //     .#{$class}__label,
    //     .#{$class}__input {
    //         color: $color-hover;
    //     }
    // }
    &.is-empty,
    &.is-empty:hover {
        background: $color-3-light;
        color: $color-empty;
        &:after {
            content: '\f071';
            @include input-icon;
        }
        .#{$class}__label,
        .#{$class}__input,
        &:after {
            color: $color-empty ;
        }
        .c-input__validation-hint {
            display: block;
        }
    }
    &.is-active,
    &.is-active:hover {
        background: #fffaf3;
        color: $color-active;
        .#{$class}__label,
        .#{$class}__input,
        &:after {
            color: $color-active ;
        }
    }
    &.is-invalid,
    &.is-invalid:hover {
        background: $color-4-light;
        color: $color-invalid;
        &:after {
            content: '\f071';
            @include input-icon;
        }
        .#{$class}__label,
        .#{$class}__input,
        &:after {
            color: $color-invalid ;
        }
        .c-input__validation-message {
            display: block;
        }
    }
    &.is-valid,
    &.is-valid:hover {
        background: $color-2-light;
        color: $color-valid;
        //change icon        
        &:after {
            content: '\f00c';
            @include input-icon;
        }
        &.c-input--dropdown:after {
            content: '\f063';
            @include input-icon;
        }
        .#{$class}__label,
        .#{$class}__input,
        &:after {
            color: $color-valid !important;
        }
    }
    @media (min-width:720px) {
        // padding-top: 18px;
        // padding-bottom: 4px;
        &__label {
            // line-height: 1.4;
        }
        &--input-button {
            max-width: none;
        }
    }
}

.c-input-group {
    display: flex;
    width: 100%;
    .c-input {
        display: table-cell;
        border-radius: 0;
        border-right-width: 0;
        &:first-child {
        }
        &:last-child {
            border-right-width: 1px;
        }
        &:hover + .c-input {
            border-left-color: $color-hover;
        }
        &.is-empty + .c-input {
            border-left-color: $color-empty;
        }
        &.is-active + .c-input {
            border-left-color: $color-active;
        }
        &.is-invalid + .c-input {
            border-left-color: $color-invalid;
        }
        &.is-valid + .c-input {
            border-left-color: $color-valid;
        }
    }
    &--width-20-40-40 {
        .c-input:nth-child(1) {
            width: 20%;
        }
        .c-input:nth-child(2) {
            width: 40%;
        }
        .c-input:nth-child(3) {
            width: 40%;
        }
    }
    &--width-33-33-33 {
        .c-input:nth-child(1) {
            width: 33.33%;
        }
        .c-input:nth-child(2) {
            width: 33.33%;
        }
        .c-input:nth-child(3) {
            width: 33.33%;
        }
    }
    &--width-50-50 {
        .c-input:nth-child(1) {
            width: 50%;
        }
        .c-input:nth-child(2) {
            width: 50%;
        }
    }
}


/*------------------------------------*\
    form
\*------------------------------------*/
.c-form {
        // @include clearfix;    

        @media (min-width:600px) {
        display:flex; 
        justify-content:space-between;   
        flex-wrap:wrap;

        .c-form__half-width {
           flex: 0 0 auto;
        width:calc(50% - 20px);
           &.c-form__half-width--align-right  {margin-left: auto;}
       }

       .c-form__half-width:last-child {margin-left: auto;}
    


    }
}


.c-input-pair {
    position: relative;
    width:100%;
    display: flex;
    flex-wrap:wrap;

    &--no-margin-bottom {
        .c-input:last-child {margin-bottom: 0;}
    }
    
    > .c-input {width:100%;}

    &--arrow {
                &:after {
            @include font-size(20px);
            // @include input-icon;
            font-family: 'FontAwesome';
            content: '\f063';
            display: block;
            right:0;
            left: 0;
            top:50px;
            margin: auto;
            width:22px;
            height: 22px;
            line-height:22px;
            text-align: center;
            color:$color-2;
            transform:translateY(-50%);  
            margin-bottom: sp(5);  
            width: 40px;
        }
        
        //reordering
        > .c-input:first-child {order:1}
        > .c-input:last-child {order:3}
        &:after {order:2;}
    }

    @media (min-width:600px) {
        display:flex; 
        justify-content:space-between;

        &--no-margin-bottom {
            .c-input {margin-bottom: 0;}
        }

            &--arrow {
            &:after {
                content: '\f061';
                height:45px;
                line-height: 45px;
                transform:translateY(0);
                margin:0;
            }
        }

        > .c-input, > .c-input-group {
            width:calc(50% - 20px);
        }
    }

}


.c-check-box-radio {
    $class: c-check-box-radio;
    margin-bottom: $sp-m;

    .c-check-box-radio__input {
        display: none;
    }
    .c-check-box-radio__label {
        // @include font-semi-bold;
        margin-right: 15px;
    }
    .c-check-box-radio__label-indicator {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 28px;
        height: 28px;
        border: 1px solid $color-input-border;
        // border-radius: 50%;
        margin-right: 8px;
    }
    .c-check-box-radio__label-text {
        display: inline-block;
        vertical-align: middle;
    }
    .c-check-box-radio__input:checked + .#{$class}__label .#{$class}__label-indicator {
        &:before {
            content: '\f00c';
            @include input-icon;
            text-align: center;
            background-repeat: no-repeat;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            right:0;
            tranform: translateY(-50%);
            color: $color-1;
        }
    }
}



