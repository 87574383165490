/* NotFound.css */
.not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    text-align: center;
    padding: 20px;
}

.not-found-content {
    max-width: 500px;
    justify-content: center;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-title {
    font-size: 3rem;
    margin-bottom: 10px;
    font-weight: 800;
    color: #000000;
}

.not-found-message {
    font-size: 24px;
    margin-bottom: 20px;
}
.not-found-message2 {
    font-size: 20px;
}
